import * as React from "react"
const UrTransparente = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M39.3 1.4C20.7 5.9 4.6 22.8 1 41.5.2 45.9 0 98.2.2 234l.3 186.5 2.4 6C10 444.4 25.1 457 42.8 460c4.8.8 45.6 1 144.7.7 125.1-.3 138.8-.5 146.5-2 20.6-4.2 38.2-10.8 54.4-20.7l8.8-5.3 37.7 37.6c20.7 20.6 39.2 38.3 41.1 39.3 2.4 1.3 5.7 1.9 10.6 1.9 6.1 0 7.7-.4 11.9-3 12-7.4 16.6-21.3 11-32.7-1-1.8-18.6-20.2-39.2-40.9l-37.6-37.7 5.3-8.8c30.7-50.8 30.2-115-1.4-164.2-16.5-25.8-44.1-48-72.3-58.2l-6.3-2.3v-60.1c0-46.9-.3-61.2-1.4-65.1-4.8-18.2-19.6-32.7-37.7-37C313.4.2 294.7 0 178.5.1 74.9.2 43.3.5 39.3 1.4zM307 102.3v51.4l-11.2.6c-63.9 3.6-121.2 51.6-137.3 114.9-6.9 27.2-6 57.6 2.5 83.8 4.1 12.9 15.6 35.6 23.5 46.5 3.6 4.9 6.5 9.3 6.5 9.8 0 .4-31.4.6-69.7.5l-69.8-.3-.3-179.3L51 51h256v51.3zm21.3 105.3c23.5 4.9 46.5 19.4 60.9 38.3 6.2 8.2 14.1 24.2 16.7 34.1 4.6 17 4.6 37.2 0 54-2.3 8.5-9.2 23.7-13.7 30.2-31.4 45.4-91.3 58.6-137.7 30.5-19.1-11.6-34.4-29.6-42.3-49.7-5.4-13.9-6.7-21-6.7-38 0-18.9 1.9-26.9 10-43.5 10.6-21.8 27.2-37.9 49.7-48.5 19.8-9.3 41.9-11.9 63.1-7.4z"  fill="currentColor"/>
  </svg>
)
export default UrTransparente

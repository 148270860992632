import * as React from "react"
const UrRentable = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M201.5 33.4c-3.7 1.7-7 5.2-8.4 8.9-.7 1.7-1.1 12.7-1.1 28.2v25.4l-18.9.3c-20.7.3-21.6.6-26.5 7.2-2 2.6-2.1 4.3-2.4 29.6l-.3 26.9-18.9.3c-20.6.3-21.5.6-26.4 7.2-2 2.6-2.1 4.1-2.4 43.4l-.3 40.7L66.3 272c-16.3 11.3-30.6 21.8-31.7 23.3-3.2 4.4-2.9 13.3.6 17.9 5.1 6.7 5.7 6.8 43.2 6.8H112l.2 74.9c.3 73.9.3 75 2.4 77.7 1.1 1.5 3.3 3.7 4.8 4.8 2.7 2.1 3 2.1 136.6 2.1s133.9 0 136.6-2.1c1.5-1.1 3.7-3.3 4.8-4.8 2.1-2.7 2.1-3.8 2.4-77.7l.2-74.9h33.6c37.5 0 38.1-.1 43.2-6.8 2.2-2.9 2.7-4.6 2.7-9.3 0-4.9-.4-6.2-3-9.3-1.7-2.1-46.2-33.5-103.5-73.2l-100.5-69.5L272 97c-.5-53.4-.6-54.9-2.6-57.6-1.1-1.5-3.3-3.7-4.8-4.8-2.6-2-4.2-2.1-31.4-2.3-22.4-.2-29.4 0-31.7 1.1zm38.5 74.4v43.8l-7.4 5.2c-4.1 2.9-7.7 5.2-8 5.2-.3 0-.6-22.1-.6-49V64h16v43.8zm-48.2 48.8-.3 28.6-7.7 5.4-7.8 5.5V128h16l-.2 28.6zm141.9 76.5c42.2 29.3 77.2 53.6 77.8 54 .5.5-23.3.9-54.6.9h-55.5l-2.6-5.4c-3.8-7.7-12.6-16.8-20.2-20.7-6.2-3.2-6.4-3.5-6.9-7.7-.5-4.8-2.7-8.4-7.1-11.6-2-1.6-4.2-2.1-8.6-2.1-4.4 0-6.6.5-8.6 2.1-4.4 3.2-6.6 6.8-7.1 11.6-.5 4.3-.8 4.6-5.8 7.1-7.7 3.8-16.7 12.6-20.6 20.2l-3.4 6.5h-55.4c-30.5 0-55.2-.2-54.9-.5C101.7 286 255.4 180 256.1 180c.4 0 35.3 23.9 77.6 53.1zm-189.9-27.9-.3 13.3-7.7 5.3-7.8 5.3V192h16.1l-.3 13.2zm121 85.5c4.4 3.3 6.3 6.7 7.2 13.3.9 6.7 2.8 10 7.4 13.4 2.7 2 4.1 2.1 45.7 2.4l42.9.3V448H144V320h66.6l2.7 5.6c7.3 14.8 22 24.4 40.4 26.3 9 .9 11.8 2.3 15.7 7.5 2.9 3.9 2.9 13.3 0 17.2-3.9 5.3-7.1 6.9-13.4 6.9-9.4 0-14.5-5-16-15.5s-6.6-15.5-16-15.5c-12.3 0-18.2 9.7-15.1 24.6 2.8 13.6 13.4 27.5 25.2 33.3 5.4 2.6 5.7 2.9 6.2 7.3.5 4.9 2.7 8.5 7.1 11.7 3.9 2.9 13.3 2.9 17.2 0 4.4-3.2 6.6-6.8 7.1-11.7.5-4.4.8-4.7 6.2-7.3 7-3.4 17-13.3 20.5-20.2 9.8-19.4 6.2-41.8-9.3-56.5-8.7-8.4-21.2-13.7-31.8-13.7-11.3 0-18.9-8.8-16.9-19.5.8-4.2 4.9-9.3 9.1-11.1 4-1.9 12-1.2 15.3 1.3z" fill="currentColor"/>
    <path d="M425.5 33.4c-6.7 2.9-10.5 10.6-9.1 18.1.7 3.4 5.7 9.7 8.6 10.7 1 .4-4.9 7.1-19.7 21.9L384 105.4l-12.2-12.1c-13.2-13-15.5-14.3-23.6-12.8-3.2.5-7.5 4.4-31.4 28.3-29.9 29.9-30 30.1-28.3 38.9.9 4.9 6.9 10.9 11.8 11.8 8.5 1.6 9.6.9 31.4-20.8l20.3-20.1 12.2 12.1c10 9.8 13 12.3 16.1 12.8 8.8 1.7 8.9 1.6 40.1-29.3 19.9-19.8 29-28.2 29.4-27.2 1 2.9 7.3 7.9 10.7 8.6 8 1.5 16.4-3.2 18.6-10.4.6-2.3.9-11.2.7-23.4-.3-21.5-.5-22.3-7.2-27.2-2.5-1.9-4.3-2.1-23.4-2.3-15.7-.2-21.4 0-23.7 1.1z"  fill="currentColor"/>
  </svg>
)
export default UrRentable

import * as React from "react"
const QvVende = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M235 42.5V64h85V21h-85v21.5zM398.2 66.3 383 81.5l15 15 15 15-7.9 7.9c-7.3 7.3-8.1 7.8-9.9 6.5-23.3-17-44.1-27.4-68.2-33.8-46.7-12.5-93.7-7.5-137 14.5-20 10.2-30.8 18.2-48 35.4-15.1 15-23.7 26.2-32 41.3-15 27.2-22 51.6-24.5 84.9l-.7 8.8h42.9l.7-9.5c4.3-61.3 45.5-113.9 103.6-132.3 16.6-5.3 25.7-6.6 45-6.6 19.8-.1 28.6 1.2 46.5 6.9 73.3 23.3 117.1 101.4 99 176.4-6.5 26.7-18.9 49.3-38 69-25.2 26.1-57.7 41.7-94 45.1-6 .5-11.6 1-12.2 1-1 0-1.3 4.9-1.3 21.2v21.1l12.3-.6c58-2.9 112.9-34 146.5-83 12-17.5 22.4-40.8 27.5-61.2 4.6-18.5 5.2-23.9 5.2-48-.1-21.2-.3-24-2.9-36-1.5-7.2-4.3-18-6.4-24-4.8-14.7-16.6-37.8-25.1-49.5l-6.9-9.5 7.9-8 7.9-8 14.8 14.8c8.1 8.1 15.2 14.7 15.7 14.7 1.3 0 29.5-28.2 29.5-29.5 0-.6-20.1-21.1-44.8-45.8L413.5 51l-15.3 15.3z"  fill="currentColor"/>
    <path d="M256 228.8v57.7l45.3 45.3c24.8 24.8 45.7 45.2 46.2 45.2 1.3 0 29.5-28.2 29.5-29.5 0-.6-17.5-18.6-39-40l-39-39V171h-43v57.8zM21 320v21h214v-42H21v21zM64 384v21h171v-42H64v21zM149 448v21h86v-42h-86v21z"  fill="currentColor"/>
  </svg>
)
export default QvVende
